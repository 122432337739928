const jQuery = require('jquery');

window.$ = window.jQuery = jQuery;

require('jquery-match-height/dist/jquery.matchHeight');


$(function() {
        $('.side-block--height').matchHeight();
});

$(window).on("load resize", function() {
    var offset = $(".bullit").offset();
    $(".side-block").css("width", offset.left);
    $(".side-block--left").css("left", -offset.left);
});